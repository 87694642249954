const BASE_URL = "/api";
const API = {
    /**
     * LOGIN API
     * GET_LOGIN_PAGE_TEMPLATE_DETAIL_BY_SCHOOL_ID 通过schoolId获取登录页模板详情
     * SEND_SMS 发短信验证码
     * LOGIN_ENTRANCE 登录整合(新登录)
     * GET_USER_TOKEN 账号密码登录(旧登录)
     */
    GET_LOGIN_PAGE_TEMPLATE_DETAIL_BY_SCHOOL_ID: BASE_URL + "/school/schoolTemplateConfig/getTemplateByCondition",
    SEND_SMS: BASE_URL + "/school/schoolAppCommon/sendSms",
    LOGIN_ENTRANCE: BASE_URL + "/auth/sc/loginEntrance",
    GET_USER_TOKEN: BASE_URL + "/auth/sc/login",
    GET_USER_INFO: BASE_URL + "/login/getUserInfo",
    DEL_ROLE: BASE_URL + "/school/schoolRole/delete",
    DEL_DICT: BASE_URL + "/school/schoolDictValue/delete",
    UPLOAD_FILE: BASE_URL + "/operate/common/uploadFile",
    GET_PROJECT_INFO: BASE_URL + "/auth/sc/getDetailByProjectMark",
    UPLOAD_BY_PEOPLE: BASE_URL + "/school/common/uploadFileByPeople",
    MOBILE_IS_REGISTER: BASE_URL + "/school/schoolUser/mobileIsRegister",

    /*
        common API
        GET_QUERY_USER_LIST   操作员下拉框
        GET_ORIGAN_SCHOOL 获取部门列表
        GET_CHILD_SCHEMA 根据父级id获取子级列表
        GET_PEOPLE_LIST 获取人员列表
        GET_TEACHER_LIST 获取教师下拉列表
        GET_COMMON_USER_LIST 获取通讯录列表
        GET_DICT_LIST   字典
    */
    GET_QUERY_USER_LIST: BASE_URL + "/operate/user/queryUserList",
    GET_ORIGAN_SCHOOL: BASE_URL + "/school/schoolOrgan/getOrganBySchool",
    GET_CHILD_SCHEMA: BASE_URL + "/school/schoolOrgan/getChildSchema",
    GET_PEOPLE_LIST: BASE_URL + "/school/schoolOrgan/getPeopleList",
    GET_TEACHER_LIST: BASE_URL + "/school/schoolTeacher/listByConditionNormal",
    GET_ADDRESS_BOOK_LIST: BASE_URL + "/school/schoolOrgan/getCommonUserList",
    GET_COMMON_USER_LIST: BASE_URL + '/school/schoolOrgan/getCommonUserList',
    GET_MYCOMMON_USER_LIST: BASE_URL + '/school/schoolOrgan/getMyCommonUserList',
    GET_DICT_LIST: BASE_URL + "/operate/common/getDictList",
    /*
        设备管理相关
        GET_EQUIPMENT_LIST         列表查询
        GET_SCHOOL_EQUIPMENT_LIST  设备管理列表（后台）
        EQUIPMENT_SCRAP            设备报废
        EQUIPMENT_UNBOUND          设备解绑
        EQUIPMENT_INSERT           设备添加
        GTE_EQUIPMENT_STATISTICS   统计接口
        GTE_SCHOOL_EQUIPMENT_STATISTICS  售卖机设备统计（后台）
        GET_EQUIPMENT_DETAILS      设备详情
        EQUIPMENT_EXPORT           设备导出
        EQUIPMENT_UPLOAD           设备信息导入
        EQUIPMENT_TEMPLATE         模板下载
        EQUIPMENT_VERIFY_EQUIPMENTCODE   设备号验重
        VENDING_SAVE               校园端保存、编辑
        AREA_DROP_DOWN 区域下拉框



    */
    AREA_DROP_DOWN: BASE_URL + "/operate/common/getAreaList",
    GET_EQUIPMENT_LIST: BASE_URL + "/operate/equipment/list",
    GET_SCHOOL_EQUIPMENT_LIST: BASE_URL + "/school/schoolEquipment/list",
    GTE_SCHOOL_EQUIPMENT_STATISTICS: BASE_URL + "/school/schoolEquipment/vendingStatistics",
    EQUIPMENT_SCRAP: BASE_URL + "/operate/equipment/scrap",
    EQUIPMENT_UNBOUND: BASE_URL + "/operate/equipment/unbound",
    EQUIPMENT_INSERT: BASE_URL + "/operate/equipment/save",
    GET_EQUIPMENT_STATISTICS: BASE_URL + "/operate/equipment/statistics",
    GET_EQUIPMENT_DETAILS: BASE_URL + "/operate/equipment/details",
    EQUIPMENT_EXPORT: BASE_URL + "/operate/equipment/export",
    EQUIPMENT_UPLOAD: BASE_URL + "/operate/equipment/upload",
    EQUIPMENT_TEMPLATE: BASE_URL + "/operate/equipment/template",
    EQUIPMENT_VERIFY_EQUIPMENTCODE: BASE_URL + "/operate/equipment/verifyEquipmentCode",
    VENDING_SAVE: BASE_URL + "/school/schoolEquipment/vendingSave",
    // /operate/equipment/equipmentUpload
    EQUIPMENT_MACHINE_UPLOAD: BASE_URL + "/operate/equipment/equipmentUpload",


    /* GET_VENDING_RECORD_LIST    交易流水列表
       VENDING_RECORD_STATISTICS     交易流水统计
     */

    GET_VENDING_RECORD_LIST: BASE_URL + "/school/vendingRecord/list",
    VENDING_RECORD_STATISTICS: BASE_URL + "/school/vendingRecord/statistics",

    /**
     * 商品限购
     * GET_GOODS_LIST       分页列表
     * SAVE_GOODS           保存/编辑
     * DELETE_GOODS         删除
     */
    GET_GOODS_LIST: BASE_URL + "/school/schoolVendingGoods/list",
    SAVE_GOODS: BASE_URL + "/school/schoolVendingGoods/save",
    DELETE_GOODS: BASE_URL + "/school/schoolVendingGoods/delete",

    /* GET_ADDRESS_LIST   获取地址列表
       DELETE_ADDRESS   删除地址
       SAVE_ADDRESS      保存、修改地址
       GET_SCHOOL_EQUIPMENT    获取学校设备列表
       GET_STUDENT_LIST            校园端获取学生列表（售卖机）
       GET_SCHOOL_EQUMENT_INFO  获取设备详情
       GET_SCHOOL_ADDRESS_LIST    获取学校设备列表
    */
    GET_ADDRESS_LIST: BASE_URL + "/school/schoolAddress/getAddressList",
    DELETE_ADDRESS: BASE_URL + "/school/schoolAddress/delete",
    SAVE_ADDRESS: BASE_URL + "/school/schoolAddress/save",
    GET_SCHOOL_EQUIPMENT:
        BASE_URL + "/school/schoolEquipment/getSchoolEquipment",
    GET_STUDENT_LIST: BASE_URL + "/school/schoolStudent/listByVending",
    GET_SCHOOL_EQUMENT_INFO: BASE_URL + "/school/schoolEquipment/info",
    GET_SCHOOL_ADDRESS_LIST:
        BASE_URL + "/school/schoolEquipment/getSchoolEquipment",

    /*
        资源配置相关
        GET_MENUS                   获取菜单项
        GET_DETAIL                  获取详情
        SET_SUBMIT                  提交接口
        GET_MENUOPEN_LIST           分页查询
        SET_MENUOPEN_SAVE           新增
        EXPORT_MENUOPEN             导入
        IMPORTINFO_MENUOPEN         导出
        FINDSTUDENT_MENUOPEN        查找学生
        MENUOPEN_TEMPLATE           模板下载
        GET_MENUOPEN_CHARGELIST     获取付费菜单
    */
    GET_MENUS: BASE_URL + "/operate/operateSchoolMenu/menu",
    GET_DETAIL: BASE_URL + "/operate/operateSchoolMenu/details",
    SET_SUBMIT: BASE_URL + "/operate/operateSchoolMenu/batchOperation",
    GET_MENUOPEN_LIST: BASE_URL + "/operate/menuOpen/list",
    SET_MENUOPEN_SAVE: BASE_URL + "/operate/menuOpen/save",
    EXPORT_MENUOPEN: BASE_URL + "/operate/menuOpen/export",
    IMPORTINFO_MENUOPEN: BASE_URL + "/operate/menuOpen/importInfo",
    FINDSTUDENT_MENUOPEN: BASE_URL + "/operate/menuOpen/findStudent",
    MENUOPEN_TEMPLATE: BASE_URL + "/operate/menuOpen/template",
    GET_MENUOPEN_CHARGELIST: BASE_URL + "/operate/operateSchoolMenu/chargeMenu",

    /*
        校内信息发布
        GET_MESSAGE_SEND_LIST                分页查询
        MESSAGE_SEND_ADD                     新增修改
        MESSAGE_SEND_DELETE                  删除
        MESSAGE_SEND_MODIFY                  修改发布状态
        MESSAGE_SEND_LIST_CONDITION          信息类型下拉列表接口
        GET_MESSAGE_DETAIL                   详情
        GET_PERSON_LIST                      人员名单列表

    */
    GET_MESSAGE_SEND_LIST: BASE_URL + "/operate/operateMessageSend/list",
    MESSAGE_SEND_ADD: BASE_URL + "/operate/operateMessageSend/save",
    MESSAGE_SEND_DELETE: BASE_URL + "/operate/operateMessageSend/delete",
    MESSAGE_SEND_MODIFY: BASE_URL + "/operate/operateMessageSend/modifyState",
    MESSAGE_SEND_LIST_CONDITION:
        BASE_URL + "/operate/operateMessageSend/listByCondition",
    GET_MESSAGE_DETAIL: BASE_URL + "/operate/operateMessageSend/info",
    DELETE_MESSAGE: BASE_URL + "/operate/operateNotice/delete",
    GET_PERSON_LIST: BASE_URL + "/school/schoolPersonBrowse/list",

    /*
        GET_CLASS_LIST_EXAMID 根据考试获取班级列表
        GET_SUBJECTS_LIST 科目 / 组合
        RESULTES_IMPORT 导入
        HEADCOUNT 总人数统计
        GET_STANDARD  获取数据库标准字段
        SEND_RESULTS  给家长发送成绩
        GET_PROJECT_DETAILS 项目详情
        SCHOOL_SYNCHRONIZE_EXAM_INFO 同步考试项目
        SCHOOL_SYNCHRONIZE_EXAM_SCORE 同步考试分数
    */
    GET_CLASS_LIST_EXAMID:
        BASE_URL + "/school/schoolExamStatistics/getClassListByExamId",
    GET_SUBJECTS_LIST:
        BASE_URL + "/school/schoolExamStatistics/getSubjectsList",
    RESULTES_IMPORT: BASE_URL + "/school/schoolExam/upload",
    HEADCOUNT: BASE_URL + "/school/schoolExam/totalPopulationStatistics",
    FRACTIONEEL_BANDNIVEAU:
        BASE_URL + "/school/schoolExamStatistics/getScoreSectionStatisticsOne",
    GET_SCORE_SECTION_STATISTICS_TWO:
        BASE_URL + "/school/schoolExamStatistics/getScoreSectionStatisticsTwo",
    GET_SCORE_SECTION_STATISTICS_THREE:
        BASE_URL +
        "/school/schoolExamStatistics/getScoreSectionStatisticsThree",
    GET_RANKING_SEGMENTS_LIST:
        BASE_URL + "/school/schoolExamStatistics/getRankingList",
    FEACTIONAL_SEGMENT_PRESERVATION:
        BASE_URL + "/school/schoolExamStatistics/saveRankingTemplate",
    AVERAGE_SCORE_STATISTICE:
        BASE_URL + "/school/schoolExamStatistics/averageScoreStatistics",
    GET_SCORE_RANKING:
        BASE_URL + "/school/schoolExamStatistics/getScoreRanking",
    GET_EXAM_STATISTICS_PARAM_DETAIL:
        BASE_URL +
        "/school/schoolExamStatisticsParam/getExamStatisticsParamDetail",
    GET_GRADE_STATISTICS_ONE:
        BASE_URL + "/school/schoolExamStatistics/getGradeStatisticsOne",
    GET_RANKING_LIST: BASE_URL + "/school/schoolExamStatistics/getRankingList",
    RANKING_STATISTICS:
        BASE_URL + "/school/schoolExamStatistics/rankingStatistics",
    PLACE_KEEPING_TEMPLATE:
        BASE_URL + "/school/schoolExamStatistics/saveRankingTemplate",
    DELETE_EXAM_PARAM:
        BASE_URL + "/school/schoolExamStatisticsParam/deleteExamParam",
    ARCHIVE_STATISTICS_SAVE:
        BASE_URL + "/school/schoolExamStatistics/archiveStatisticsSave",
    ARCHIVE: BASE_URL + "/school/schoolExamStatistics/archiveSave",
    UPSCALE_STATISTICS_SECONDARY:
        BASE_URL + "/school/schoolExamStatistics/getGradeStatisticsTwo",
    UPSCALE_STATISTICS_THREE:
        BASE_URL + "/school/schoolExamStatistics/getGradeStatisticsThree",
    INDIVIDUAL_STUDENT_ACHIEVEMENTS:
        BASE_URL + "/school/schoolExamStatistics/getStudentExamInformation",
    HOMOGENEITY_ANALYSIS:
        BASE_URL + "/school/schoolExamStatistics/equilibriumAnalysis",
    PROGRESS_REGRESSION_GLANCE:
        BASE_URL + "/school/schoolExamStatistics/advanceAndRetreat",
    GET_MY_GROW_UP: BASE_URL + "/school/schoolExamStatistics/getMyGrowUp",
    GET_SUBJECT_ONE: BASE_URL + "/school/schoolExamStatistics/getSubjectOne",
    GET_SUBJECT_TWO: BASE_URL + "/school/schoolExamStatistics/getSubjectTwo",
    GET_SUBJECT_THREE:
        BASE_URL + "/school/schoolExamStatistics/getSubjectThree",
    PERCENTAGE_ACHIEVEMENTS:
        BASE_URL + "/school/schoolExamStatistics/percentageOfAchievements",
    GET_STANDARD: BASE_URL + "/school/schoolExam/getStandardFields",
    GET_ARCHIVE_FIRST_LIST:
        BASE_URL + "/school/schoolExamStatistics/getArchiveFirstList",
    GET_ARCHIVE_LIST: BASE_URL + "/school/schoolExamStatistics/getArchiveList",
    SEND_RESULTS: BASE_URL + "/school/schoolExamStatistics/sendResults",
    GET_EXAM_STATUS: BASE_URL + "/school/schoolExam/getExamInfo",
    SET_HJ_SHOW_EXAM_BUTTON: BASE_URL + "/school/schoolExam/editShowExamButton",
    SET_EXAM_STATUS: BASE_URL + "/school/schoolExam/editExamStatus",
    GET_PROJECT_DETAILS: BASE_URL + "/operate/operateSchool/getDetailById/", ///operateSchool/getDetailById/{id}
    SCHOOL_SYNCHRONIZE_EXAM_INFO:
        BASE_URL + "/school/schoolExam/synchronizeExamInfo",
    SCHOOL_SYNCHRONIZE_EXAM_SCORE:
        BASE_URL + "/school/schoolExam/synchronizeExamScoreForYxy",
    UPLOAD_SCORE_ZHX_LIST:
		BASE_URL + "/school/schoolExam/uploadScoreZhx",

    /*
        修改主题
    */
    EDIT_USER_TEMPLATE_INFO: BASE_URL + "/school/schoolUser/edit",

    /*
      校园安全 > 出入校设置
      GET_ISSUE_SCOPE_BY_ACCESS   全部学生列表
      GET_ACCESS_GROUP_LIST       分页查询
      SAVE_ACCESS_GROUP           添加保存
      DELETE_ACCESS_GROUP         删除
      IS_ENABLE_ACCESS_GROUP      启用禁用
      UPLOAD_ISSUE_SCOPE_RECORD   上传
      setBatchSettingPickUp
      BATCH_SETTING_PICK_UP      批量设置接送人
      GET_PUSH_NOTICE_LIST       获取推送通知信息
      UPDATE_PUSH_NOTICE_LIST       修改推送通知信息
      IMPORT_PERSON              导入人员
      GET_GROUP_TIME_TYPE       获取分组时间设置类型
      SET_GROUP_TIME_TYPE       保存分组时间设置类型
      GET_GROUP_PERSON_LIST     获取人员维度的出入信息列表
      SAVE_ACCESS_GROUP_PERSON     保存人员维度的出入信息
      GET_ACCESS_GROUP_DO_NOT_PROMPT     获取人员编辑不提醒设置
      SET_ACCESS_GROUP_DO_NOT_PROMPT     保存人员编辑不提醒设置
      DELETE_ACCESS_PERSON     删除人员维度的出入信息
   */

    GET_ISSUE_SCOPE_BY_ACCESS:
        BASE_URL + "/school/schoolOrgan/getIssueScopeByAccess",
    GET_ACCESS_GROUP_LIST: BASE_URL + "/accessSchool/schoolAccessGroup/list",
    SAVE_ACCESS_GROUP: BASE_URL + "/accessSchool/schoolAccessGroup/save",
    DELETE_ACCESS_GROUP: BASE_URL + "/accessSchool/schoolAccessGroup/delete",
    IS_ENABLE_ACCESS_GROUP:
        BASE_URL + "/accessSchool/schoolAccessGroup/isEnable",
    UPLOAD_ISSUE_SCOPE_RECORD: BASE_URL + "/parent/schoolInterconnection/onlyCodeUpload",
    BATCH_SETTING_PICK_UP: BASE_URL + "/parent/schoolInterconnection/batchSetPickUp",
    GET_PUSH_NOTICE_LIST: BASE_URL + "/school/schoolUserPreferenceDynamicConfig/getSchoolFlagConfigBySchoolId",
    UPDATE_PUSH_NOTICE_LIST: BASE_URL + "/school/schoolUserPreferenceDynamicConfig/saveOrUpdateSchoolFlagConfigBySchoolId",
    IMPORT_PERSON: BASE_URL + "/accessSchool/schoolAccessGroup/importPerson",
    GET_GROUP_TIME_TYPE: BASE_URL + "/accessSchool/schoolAccessGroup/getTimeType",
    SET_GROUP_TIME_TYPE: BASE_URL + "/accessSchool/schoolAccessGroup/setTimeType",
    GET_GROUP_PERSON_LIST: BASE_URL + "/accessSchool/schoolAccessGroup/personList",
    SAVE_ACCESS_GROUP_PERSON: BASE_URL + "/accessSchool/schoolAccessGroup/personListEdit",
    GET_ACCESS_GROUP_DO_NOT_PROMPT: BASE_URL + "/accessSchool/schoolAccessGroup/getDoNotPrompt",
    SET_ACCESS_GROUP_DO_NOT_PROMPT: BASE_URL + "/accessSchool/schoolAccessGroup/setDoNotPrompt",
    DELETE_ACCESS_PERSON: BASE_URL + "/accessSchool/schoolAccessGroup/personListDelete",

    /*
      校园安全 > 出入校记录
      GET_ACCESS_RECORD_LIST       分页查询
   */
    GET_ACCESS_RECORD_LIST: BASE_URL + "/accessSchool/accessRecord/list",
    /*
        校园OA > 审批管理 > 表单管理
        GET_SCHOOL_FORM_GROUP_LIST      分页查询
        CONDITION_LIST                  列表查询
        SCHOOL_FORM_GROUP_SAVE          保存
        SCHOOL_FORM_GROUP_SORT          分组排序
        SCHOOL_FORM_GROUP_INFO          查询详情
        SCHOOL_FORM_GROUP_DELETE        批量删除
        GET_SCHOOL_FORM_GROUP           表单列表
        SCHOOL_FORM_INFO_SAVE           创建菜单
        SCHOOL_FORM_INFO_COPY           复制表单
        SAVE_SIGN                  保存签名
        GET_HISTORY_SIGN                获取历史签名
    */
    GET_SCHOOL_FORM_GROUP_LIST: BASE_URL + "/oa/schoolFormGroup/list",
    CONDITION_LIST: BASE_URL + "/oa/schoolFormInfo/list",
    SCHOOL_FORM_GROUP_SAVE: BASE_URL + "/oa/schoolFormGroup/save",
    SCHOOL_FORM_GROUP_SORT: BASE_URL + "/oa/schoolFormGroup/sort",
    SCHOOL_FORM_GROUP_INFO: BASE_URL + "/oa/schoolFormGroup/info",
    SCHOOL_FORM_GROUP_DELETE: BASE_URL + "/oa/schoolFormGroup/delete",
    GET_SCHOOL_FORM_GROUP: BASE_URL + "/oa/schoolFormGroup/listByCondition",
    SCHOOL_FORM_INFO_SAVE: BASE_URL + "/oa/schoolFormInfo/save",
    SCHOOL_FORM_INFO_COPY: BASE_URL + "/oa/schoolFormInfo/copyFormInfo",
    SAVE_SIGN: BASE_URL + "/oa/handwrittenSignature/saveHandwrittenSignature",
    GET_HISTORY_SIGN: BASE_URL + "/oa/handwrittenSignature/findUserHandwrittenSignature",
    /*
        校园OA > 审批管理 > 流程交接
        GET_PROCESS_HAND_OVER_LIST      分页查询
        SAVE_SCHOOL_FORM_GROUP          发起交接
        GET_CONDITION_BY_LIST           组织机构

    */
    GET_PROCESS_HAND_OVER_LIST: BASE_URL + "/oa/schoolProcessHandOver/list",
    SAVE_SCHOOL_FORM_GROUP: BASE_URL + "/oa/schoolProcessHandOver/save",
    GET_CONDITION_BY_LIST:
        BASE_URL + "/school/schoolOrgan/getIssueScopeByAccess",
    GET_HANDOVER_FORM_LIST: BASE_URL + "/oa/schoolFormInfo/listByCondition",
    SCHOOL_FORM_MOBILE_GROUP: BASE_URL + "/oa/schoolFormInfo/mobileGroup",
    /*
        家校互联 > 家长通讯录
        GET_PARENT_DIRECTORY_LIST       分页查询
        SAVE_PARENT_DIRECTORY_LIST      保存
        IS_PICK_UP                      是否接送人
        DOWNLOAD_PARENT_DIRECTORY       下载模板
        GET_SCHOOL_ORGAN                获取组织机构
    */
    GET_PARENT_DIRECTORY_LIST2: BASE_URL + '/parent/schoolInterconnection/list',
    GET_PARENT_DIRECTORY_LIST: BASE_URL + "/school/schoolInterconnection/list",
    SAVE_PARENT_DIRECTORY: BASE_URL + "/parent/schoolInterconnection/save",
    DELETE_PARENT_DIRECTORY: BASE_URL + "/parent/schoolInterconnection/delete",
    IS_PICK_UP: BASE_URL + "/parent/schoolInterconnection/isPickUp",
    DOWNLOAD_PARENT_DIRECTORY:
        BASE_URL + "/parent/schoolInterconnection/template",
    GET_SCHOOL_ORGAN: BASE_URL + "/school/schoolOrgan/getSchoolOrgan",
    /*
     校园OA > 审批管理 > OA审批
     GET_SCHOOL_PROCESS_APPLY_LIST      oa审批列表分页查询
     GET_FORM_INFO_DATA                 oa审批列表详情
     SET_SCHOOL_FORM_INFO_SORT          oa审批管理页面排序
     GET_SHIFT_CHANGE_DATE              获取可选换班时间（排班日期）
     GET_DORM_INFO                      获取宿舍信息
     GET_HALF_DAY_REAL_TIME             按半天请假选择日期后获取时分秒
     CHECK_ALLOW_HALF_DAY_LEAVE         校验是否允许请半天假
     SCHOOL_PROCESS_APPLY_INFO_CHECK_DATE 校验相同时间段是否有重复的请假单
     GET_LEAVE_DURATION_DETAILS         获取请假时长明细
     SCHOOL_PROCESS_APPLY_SPECIAL_VACATION_DURATION 单个请假组的请假时长
     GET_STAFF_COMPENSATORY_LEAVE_DAY   查询教师剩余多少调休
     GET_STAFF_COMPENSATORY_LEAVE_DAY   特殊请假查询请假时间段重复
     GET_CHILD_APPLY_INFO 特殊请假子假条列表
     GET_CHECK_IS_MASTER_TEACHER   判断当前教师是否为班主任

     SCHOOL_VACATION_GET_LEAVE_BALANCE    获取员工剩余假期及详情
     SCHOOL_PROCESS_APPLY_HAS_ONGOING_APPLICATION    判断是否有审批中请假单（设置了假期余额balanceSwitch为1请求）

     SCHOOL_VACATION_GET_LEAVE_BALANCE    获取员工剩余假期及详情
     SCHOOL_PROCESS_APPLY_HAS_ONGOING_APPLICATION    判断是否有审批中请假单（设置了假期余额balanceSwitch为1请求）
     GET_CHECK_IS_MASTER_TEACHER   判断当前教师是否为班主任
     */
    GET_SCHOOL_PROCESS_APPLY_LIST: BASE_URL + "/oa/schoolProcessApply/list",
    GET_FORM_INFO_DATA: BASE_URL + "/oa/schoolFormInfo/info/",
    SAVE_SCHOOL_PROCESS_APPLY: BASE_URL + "/oa/schoolProcessApply/save",
    GET_PROCESS: BASE_URL + "/oa/schoolProcessApply/getProcess",
    SCHOOL_PROCESS_APPLY_INFO: BASE_URL + "/oa/schoolProcessApply/info",
    SCHOOL_INTERCONNECTION:
        BASE_URL + "/parent/schoolInterconnection/listByCondition",
    SCHOOL_PROCESS_APPROVAL: BASE_URL + "/oa/schoolProcessApply/approval",
    SCHOOL_PROCESS_APPLY_REVOKE: BASE_URL + "/oa/schoolProcessApply/revoke",
    SCHOOL_PROCESS_APPLY_REVOKEALL: BASE_URL + "/oa/schoolProcessApply/revokeAll",
    SCHOOL_PROCESS_APPLY_EDIT: BASE_URL + "/oa/schoolProcessApply/edit",
    SET_SCHOOL_FORM_INFO_SORT: BASE_URL + "/oa/schoolFormInfo/sort",
    GET_CONTENT_DETAIL: BASE_URL + "/oa/schoolText/getById",
    GET_FOR_MORE_INFO: BASE_URL + "/oa/schoolFormInfo/info/",
    SET_UPDATE_STATUS: BASE_URL + "/oa/schoolFormInfo/updateStatus",
    GET_PERSONNEL_INFO: BASE_URL + "/school/schoolOrgan/getUserByOrgan",
    GET_SHIFT_CHANGE_DATE: BASE_URL + "/school/schoolDormitoryScheduling/getSchedulingDate",
    GET_DORM_INFO: BASE_URL + "/school/schoolDormitoryScheduling/getSchedulingRoom",
    GET_HALF_DAY_REAL_TIME: BASE_URL + '/school/schoolCheckAttendanceRule/realApplyTime',
    CHECK_ALLOW_HALF_DAY_LEAVE: BASE_URL + '/school/schoolCheckAttendanceRule/checkAllowHalfAdayoff',
    SCHOOL_PROCESS_APPLY_INFO_CHECK_DATE: BASE_URL + "/oa/schoolProcessApply/checkApplyDate",
    GET_LEAVE_DURATION_DETAILS: BASE_URL + '/school/schoolCheckAttendanceRule/teacherLeaveDetial',
    SCHOOL_PROCESS_APPLY_SPECIAL_VACATION_DURATION: BASE_URL + "/oa/schoolProcessApply/getSpecialVacationDuration",
    GET_STAFF_COMPENSATORY_LEAVE_DAY: BASE_URL + "/oa/schoolVacation/getStaffCompensatoryLeaveDay",
    SCHOOL_PROCESS_APPLY_CHECK_DATE_BY_SPECIAL: BASE_URL + "/oa/schoolProcessApply/checkApplyDateBySpecial",
    GET_CHILD_APPLY_INFO: BASE_URL + "/oa/schoolProcessApply/getChildApplyInfo",
    GET_CHECK_IS_MASTER_TEACHER: BASE_URL + "/school/schoolTeacher/checkIsMasterTeacher",

    SCHOOL_VACATION_GET_LEAVE_BALANCE: BASE_URL + "/oa/schoolVacation/getLeaveBalance",
    SCHOOL_PROCESS_APPLY_HAS_ONGOING_APPLICATION: BASE_URL + "/oa/schoolProcessApply/hasOngoingApplication",

    SCHOOL_VACATION_GET_LEAVE_BALANCE: BASE_URL + "/oa/schoolVacation/getLeaveBalance",
    SCHOOL_PROCESS_APPLY_HAS_ONGOING_APPLICATION: BASE_URL + "/oa/schoolProcessApply/hasOngoingApplication",
    GET_CHECK_IS_MASTER_TEACHER: BASE_URL + "/school/schoolTeacher/checkIsMasterTeacher",
    /*
     校园安全 > 体温监测
     GET_TEMPERATURE_LIST      体温监测列表分页查询

     */
    GET_TEMPERATURE_LIST: BASE_URL + "/school/schoolTemperature/list",
    EQUIPMENT_SCHOOL_ADDRESS_LIST:
        BASE_URL + "/operate/schoolAddress/getAddressList",
    GET_SCHOOL_ORGAN_ALL: BASE_URL + "/school/schoolOrgan/listByCondition",
    SCHOOL_ADDRESS_SAVE: BASE_URL + "/operate/schoolAddress/save",
    SCHOOL_ADDRESS_DELETE: BASE_URL + "/operate/schoolAddress/delete",

    /*
      宿舍楼
      GET_SCHOOL_DORMITORY_LIST              宿舍楼分页查询
      GET_SCHOOL_DORMITORY_LIST_CONDITION    宿舍楼不分页
      GET_SCHOOL_DORMITORY_FLOOR_LIST_CONDITION    宿舍楼楼层不分页
      GET_DORMITORY_ORGAN_LIST               获取组织架构
      GET_STU_LIST_BY_DORMITORY              获取学生列表
      SAVE_SCHOOL_DORMITORY                  宿舍楼保存/修改
      DELETE_SCHOOL_DORMITORY                宿舍楼删除
      DOWNLOAD_SCHOOL_DORMITORY_TEMPLATE     宿舍楼模板下载
      UPLOAD_SCHOOL_DORMITORY                宿舍楼导入
      宿舍管理
      GET_SCHOOL_DORMITORY_ROOM_LIST    宿舍分页查询
      SAVE_SCHOOL_DORMITORY_ROOM        宿舍保存/修改
      DELETE_SCHOOL_DORMITORY_ROOM      宿舍删除
      DOWNLOAD_SCHOOL_DORMITORY_ROOM_TEMPLATE   下载模板
      SCHOOL_DORMITORY_ROOM_DISTRIBUTE   分配
      宿舍分配
      GET_DORMITORY_DISTRIBUTION_LIST    列表
      DELETE_DORMITORY_DISTRIBUTION      删除
      GET_ROOM_DISTRIBUTE_LIST           宿舍调整分配列表查询
      SAVE_ROOM_DISTRIBUTE               宿舍调整分配
      宿舍考勤明细
      GET_ATTENDANCE_DETAILS             获取列表
      GET_ATTENDANCE_DETAIL_STATISTICS   宿舍考勤明细统计
      宿舍分配-按宿舍
      GET_ROOM_DISTRIBUTE_LIST           按宿舍查询已分配学生
      GET_SCHOOL_STUDENT_LIST            当前宿舍楼中的学生查询不分页
      GET_ROOM_TOTAL_DISTRIBUTION        宿舍概况信息
      GET_ROOM_TOTAL_SORT_SAVE           宿舍分配排序保存
     */
    GET_ROOM_TOTAL_SORT_SAVE: BASE_URL + "/school/schoolDormitoryRoom/distributeByDormitory",
    GET_DORMITORY_LIST: BASE_URL + "/school/schoolDormitoryRoom/listForDistribute",
    GET_SCHOOL_DORMITORY_LIST: BASE_URL + "/school/schoolDormitory/list",
    GET_SCHOOL_STUDENT_LIST: BASE_URL + "/school/schoolDormitoryDistribution/listByRoomId",
    GET_ROOM_TOTAL_DISTRIBUTION: BASE_URL + "/school/schoolDormitoryRoom/getRoomTotalByCondition",
    GET_SCHOOL_DORMITORY_LIST_CONDITION:
        BASE_URL + "/school/schoolDormitory/listByCondition",
    GET_SCHOOL_DORMITORY_FLOOR_LIST_CONDITION:
        BASE_URL + "/school/schoolDormitoryFloor/listByCondition",
    GET_DORMITORY_ORGAN_LIST:
        BASE_URL + "/school/schoolOrgan/dormitoryOrganList",
    GET_STU_LIST_BY_DORMITORY:
        BASE_URL + "/school/schoolStudent/getStuListByDormitory",
    SAVE_SCHOOL_DORMITORY: BASE_URL + "/school/schoolDormitory/save",
    DELETE_SCHOOL_DORMITORY: BASE_URL + "/school/schoolDormitory/delete",
    DOWNLOAD_SCHOOL_DORMITORY_TEMPLATE:
        BASE_URL + "/school/schoolDormitory/template",
    UPLOAD_SCHOOL_DORMITORY: BASE_URL + "/school/schoolDormitory/upload",
    GET_ROOM_TOTAL: BASE_URL + "/school/schoolDormitoryRoom/getRoomTotal",
    GET_SCHOOL_DORMITORY_LIST_ROOM:
        BASE_URL + "/school/schoolDormitoryRoom/list",
    SAVE_SCHOOL_DORMITORY_ROOM: BASE_URL + "/school/schoolDormitoryRoom/save",
    DELETE_SCHOOL_DORMITORY_ROOM:
        BASE_URL + "/school/schoolDormitoryRoom/delete",
    DOWNLOAD_SCHOOL_DORMITORY_ROOM_TEMPLATE:
        BASE_URL + "/school/schoolDormitoryRoom/template",
    SCHOOL_DORMITORY_ROOM_DISTRIBUTE:
        BASE_URL + "/school/schoolDormitoryRoom/distribute",
    GET_DORMITORY_DISTRIBUTION_LIST:
        BASE_URL + "/school/schoolDormitoryDistribution/list",
    DELETE_DORMITORY_DISTRIBUTION:
        BASE_URL + "/school/schoolDormitoryDistribution/delete",
    GET_ROOM_DISTRIBUTE_LIST:
        BASE_URL + "/school/schoolDormitoryRoom/roomDistributeList",
    SAVE_ROOM_DISTRIBUTE:
        BASE_URL + "/school/schoolDormitoryDistribution/distribute",
    GET_ATTENDANCE_DETAILS:
        BASE_URL + "/school/schoolDormitoryDistribution/attendanceDetails",
    SAVE_MESSAGE_SETTING: BASE_URL + "/school/messsageSetting/save",
    GET_MESSAGE_SETTING: BASE_URL + "/school/messsageSetting/getOne",
    GET_ATTENDANCE_DETAIL_STATISTICS:
        BASE_URL +
        "/school/schoolDormitoryDistribution/attendanceDetailStatistics",

    /**
     * 教务综合 > 学生档案
     * CONDITION_BY_LIST 档案列表查询
     * SEGEMT_CONDITION_MENU_TREE_LIST 更多列表查询
     * CONDITION_SAVE 档案分享提交接口
     * */
    SEGEMT_CONDITION_MENU_TREE_LIST: BASE_URL + "/operate/operateSchoolMenu/getSegmentMenuTree",
    CONDITION_BY_LIST: BASE_URL + "/school/schoolArchivesType/listByCondition",
    CONDITION_SAVE: BASE_URL + "/school/schoolArchivesShareSetting/save",
    REMOVE_SCHOOL_TEACHER: BASE_URL + "/school/schoolTeacher/remove",
    /**
     * 仓库资产列表
     *
     */
    GET_STORE_LIST: BASE_URL + "/school/schoolStoreAssets/statisList",
    /**
     * 部门资产列表
     *
     */
    GET_DEPT_LIST: BASE_URL + "/school/schoolDeptAssets/statisList",

    // 仓库管理
    WAREHOUSE_DELETE: BASE_URL + "/school/schoolStore/delete",

    /**
     * 资产管理/ 部门资产/资产维护
     * 仓库名称列表 GET_WAREHOUSE_NAME
     * 资产品类 GET_ASSET_CLASSES_LIST
     * 资产维护列表 GET_ASSET_MAINTENANCE_LIST
     * 资产维护操作弹出详情 GET_OPERATION_POPUP_DETAILS
     * 资产归还 ASSET_REPATRIATION
     * 资产报修 ASSET_REPAIR
     * 资产报废 ASSET_RETIREMENT
     * 资产详情 ASSET_DETAIL
     *
     * 部门资产流水列表 DEPARTMENTAL_ASSET_FLOW
     * 部门资产流水导出
     * */

    GET_WAREHOUSE_NAME: BASE_URL + "/school/schoolStore/listByCondition",
    GET_ASSET_CLASSES_LIST:
        BASE_URL + "/school/schoolStoreCategory/listByCondition",
    GET_ASSET_MAINTENANCE_LIST: BASE_URL + "/school/schoolDeptAssets/list",
    GET_OPERATION_POPUP_DETAILS:
        BASE_URL + "/school/schoolDeptAssetsRecord/repairInfo",
    ASSET_REPATRIATION: BASE_URL + "/school/schoolDeptAssetsIn/assetReturn",
    ASSET_REPAIR: BASE_URL + "/school/schoolDeptAssetsRecord/repair",
    ASSET_RETIREMENT: BASE_URL + "/school/schoolDeptAssetsIn/assetRetirement",
    ASSET_DETAIL: BASE_URL + "/school/schoolDeptAssets/info",

    DEPARTMENTAL_ASSET_FLOW: BASE_URL + "/school/schoolDeptAssetsRecord/list",
    EXPORT_SCHOOL_DEPT_ASSETS:
        BASE_URL + "/school/schoolDeptAssetsRecord/export",
    INITIAL_IMPORT: BASE_URL + "/school/schoolStoreAssets/importOfAssets",

    /**
     * 课程表
     * 科目列表 GET_SCHOOL_SUBJECTS_LIST
     * 班级列表 GET_SCHOOL_CLASS_LIST
     * 教师列表 GET_SCHOOL_TEACHER_LIST
     * 年级列表 GET_GRADE_LIST
     * 年级树形列表 COURSE_SCHEDULE_GET_GRADE_TREE_SHAPE
     * 根据年级获取班级 COURSE_SCHEDULE_YEAR_GET_CLASS
     *
     * *校历管理页面
     * 校历管理分页列表 GET_SCHOOL_CALENDAR_LIST
     * 添加/编辑校历管理 GET_SCHOOL_CALENDAR_SAVE
     * 查看校历页面  GET_SCHOOL_CALENDAR
     *
     * *作息时间表页面
     * 作息时间表分页列表 GET_SCHOOL_SCHEDULE_LIST
     * 添加/编辑作息时间 GET_SCHOOL_SCHEDULE_SAVE
     *
     * *巡课内容页面
     * 巡课内容分组列表页面 GET_BEHAVIOR_LABEL_LIST
     * 巡课内容分组-保存/编辑 GET_BEHAVIOR_LABEL_SAVE
     * 巡课内容分页列表 GET_BEHAVIOR_DATA_LIST
     * 巡课内容保存/编辑 GET_BEHAVIOR_DATA_SAVE
     * 行为数据列表查询接口 GET_BEHAVIOR_LOOK
     *
     * *课堂观察记录页面
     * 课堂观察记录页面列表接口 GET_CLASSSROOM_RECORD_LIST
     * 获取部门 GET_DEPARTMENT_LIST
     *
     * *异常提醒及反馈
     * 获取异常详情 GET_ABNORMAL_DETAIL
     * 异常反馈 GET_ABNORMAL_SAVE
     *
     * *课程表页面
     * 课程表保存接口 SCHOOL_COURSE_SAVE
     * 获取上课星期接口 GET_CLASS_WEEK
     * 校验课程是否存在重复 GET_COURSE_DUPLICATION
     * 校验所选时间段是否在一个校历时间内 GET_CALIBRATE_CALENDAR_TIME
     * 列表接口 GET_SCHOOL_COURSE_LIST
     *
     * *巡课
     * 教师和学生的行为标签取值 TEACHER_STUDENT_BEHAVIOR_LABELS_TAKE_VALUES
     * 保存接口 SCHOOL_CLASS_RECORD_SAVE
     * 详情 GET_SCHOOL_CLASS_RECORD_INFO
     * 继续巡课详情 GET_CONTINUE_TOUR_INFO
     * 根据教师id获取部门 GET_TEA_ORGAN_BY_TEACHER_ID
     * 根据年级id获取班级列表 GET_CLASS_BY_GRADE_ID
     * 获取课程信息 GET_COURSE_INFO
     * 当前日期排课数量 GET_SCHOOL_COURSE_COUNT
     * 清空课表前增加校验是否已设置巡课方案 GET_EMPTY_BEFORE_CHECK
     *
     */
    GET_SCHOOL_SUBJECTS_LIST:
        BASE_URL + "/school/schoolDictValue/listByCondition",
    GET_SCHOOL_CLASS_LIST: BASE_URL + "/school/schoolOrgan/listByCondition", //BASE_URL + '/school/schoolOrgan/listByCondition',
    GET_SCHOOL_TEACHER_LIST:
        BASE_URL + "/school/schoolOrgan/getIssueScopeByAccess", // /school/schoolTeacher/queryTeacherList
    GET_GRADE_LIST: BASE_URL + "/school/schoolOrgan/getGradeOrgan",
    GET_SCHOOL_CALENDAR_LIST: BASE_URL + "/school/schoolCalendar/list",
    GET_SCHOOL_CALENDAR_SAVE: BASE_URL + "/school/schoolCalendar/save",
    GET_SCHOOL_CALENDAR: BASE_URL + "/school/schoolCalendar/getCalendar",

    SCHOOL_COURSE_SAVE: BASE_URL + "/school/schoolCourse/save",
    GET_CLASS_WEEK: BASE_URL + "/school/schoolRestTime/listByCondition",
    GET_COURSE_DUPLICATION: BASE_URL + "/school/schoolCourse/checkRepeat",
    GET_CALIBRATE_CALENDAR_TIME:
        BASE_URL + "/school/schoolCourse/checkCalendar",
    GET_SCHOOL_COURSE_LIST: BASE_URL + "/school/schoolCourse/list",
    GET_SCHOOL_COURSE_LIST_BYAUTH: BASE_URL + "/school/schoolCourse/listByAuth",
    DELETE_SCHOOL_COURSE: BASE_URL + "/school/schoolCourse/delete",

    TEACHER_STUDENT_BEHAVIOR_LABELS_TAKE_VALUES:
        BASE_URL + "/school/schoolBehaviorLabel/getSchoolBehaviorLabelList",
    SCHOOL_CLASS_RECORD_SAVE: BASE_URL + "/school/schoolClassRecord/save",
    GET_SCHOOL_CLASS_RECORD_INFO: BASE_URL + "/school/schoolClassRecord/info",
    GET_CONTINUE_TOUR_INFO:
        BASE_URL + "/school/schoolClassRecord/continueTourInfo",
    GET_TEA_ORGAN_BY_TEACHER_ID:
        BASE_URL + "/school/schoolTeacher/getTeaOrganByTeacherId",
    GET_CLASS_BY_GRADE_ID: BASE_URL + "/school/schoolOrgan/getClassByGradeId",
    GET_COURSE_INFO: BASE_URL + "/school/schoolCourse/listByCondition",

    GET_SCHOOL_SCHEDULE_LIST: BASE_URL + "/school/schoolRestTime/list",
    GET_SCHOOL_SCHEDULE_SAVE: BASE_URL + "/school/schoolRestTime/save",

    GET_BEHAVIOR_LABEL_LIST:
        BASE_URL + "/school/schoolPatrolGroup/listByCondition",
    GET_BEHAVIOR_LABEL_SAVE: BASE_URL + "/school/schoolPatrolGroup/save",
    GET_BEHAVIOR_DATA_LIST: BASE_URL + "/school/schoolPatrolContent/list",
    GET_BEHAVIOR_DATA_SAVE: BASE_URL + "/school/schoolPatrolContent/save",
    GET_BEHAVIOR_LOOK: BASE_URL + "/school/schoolBehaviorData/listByCondition",

    GET_CLASSSROOM_RECORD_LIST: BASE_URL + "/school/schoolClassRecord/list",
    GET_ABNORMAL_DETAIL: BASE_URL + "/school/schoolClassEvaluate/info",
    GET_ABNORMAL_SAVE: BASE_URL + "/school/schoolClassEvaluate/feedback",
    GET_DEPARTMENT_LIST_ORGAN: BASE_URL + "/school/schoolOrgan/getDepartmentOrgan",
    COURSE_SCHEDULE_GET_GRADE_TREE_SHAPE:
        BASE_URL + "/school/schoolOrgan/getGradeOfTree",
    COURSE_SCHEDULE_GET_GRADE_TREE_BYUSER:
        BASE_URL + "/school/schoolOrgan/getGradeTreeByUser",
    COURSE_SCHEDULE_YEAR_GET_CLASS:
        BASE_URL + "/school/schoolOrgan/spliceOrganList",
    COURSE_EMPTY:BASE_URL + "/school/schoolCourse/empty",
    BATCH_DELETE_COURSE:BASE_URL + "/school/schoolCourse/batchBelete",
    COURSE_IMPORT_INFO:BASE_URL + "/school/schoolCourse/importCourse",
    DOWNLOAD_ERROR_EXCEL:BASE_URL + "/school/schoolCourse/downloadErrorExcel",
    Grade_CLASS_LIST:BASE_URL + "/school/schoolOrgan/listAuthByCondition",
    CLASSNAME_MASTERTEACHER:BASE_URL + "/school/schoolOrgan/getClassNameAndMasterTeacher",
    GET_SCHOOL_COURSE_COUNT:BASE_URL + "/school/schoolCourse/courseCount",
    GET_EMPTY_BEFORE_CHECK:BASE_URL + "/school/schoolCourse/emptyBeforeCheck",
    /**
     * 获取部门/人下拉 GET_DEPARTMENT_PEOPLE
     * 树形懒加载 获取教师第一层接口 GET_SCHOOL_ORGANIZATION_TREE
     * 树形懒加载 根据教师id获取接口 GET_SCHOOL_ORGANIZATION_TREE
     * 查询教师与部门名称 GET_TEACHERS_INFO
     * 公共字典 GET_CONDITION_LIST_CLASSROOM
     * 楼栋列表 GET_BUILDING_LIST
     * 楼层列表 GET_BUILDING_FLOOR_LIST
     * */

    GET_DEPARTMENT_PEOPLE:
        BASE_URL + "/school/schoolStoreAssetsRecord/getPeopleList",
    GET_SCHOOL_ORGANIZATION_TREE:
        BASE_URL + "/school/schoolOrgan/getTeacherOrgan",
    GET_TEACHERS_LIST_BY_ID:
        BASE_URL + "/school/schoolTeacher/getTeacherByPostId",
    GET_TEACHERS_INFO:
        BASE_URL + "/school/schoolTeacher/getTeacherOrOrganByIds",
    GET_CONDITIONS_LIST: BASE_URL + "/school/schoolDictValue/listByCondition",
    GET_BUILDING_LIST: BASE_URL + "/school/schoolBuilding/listByCondition",
    GET_BUILDING_FLOOR_LIST:
        BASE_URL + "/school/schoolBuildingFloor/listByCondition",
    CLASSROOM_SAVE: BASE_URL + "/school/schoolBuildingRoom/save",
    IMPORT_EXCEL: BASE_URL + "/school/schoolBuildingRoom/importExcel",

    /**
     * 教室管理列表 GET_CLASSROOM_LIST
     * 教室管理列表删除 DELETE_SCHOOL_BUILDING_ROOM
     * */
    GET_CLASSROOM_LIST: BASE_URL + "/school/schoolBuildingRoom/list",
    DELETE_SCHOOL_BUILDING_ROOM: BASE_URL + "/school/schoolBuildingRoom/delete",

    /**
     * 楼栋管理列表 GET_FLOOR_LIST
     * 楼栋管理列表、批量删除 DELETE_SCHOOL_FLOOR_ROOM
     * 楼栋管理导入 IMPORT_EXCEL_FLOOR
     * 楼栋管理新增、编辑 FLOOR_SAVE
     * */
    GET_FLOOR_LIST: BASE_URL + "/school/schoolBuilding/list",
    DELETE_SCHOOL_FLOOR_ROOM: BASE_URL + "/school/schoolBuilding/delete",
    IMPORT_EXCEL_FLOOR: BASE_URL + "/school/schoolBuilding/importExcel",
    FLOOR_SAVE: BASE_URL + "/school/schoolBuilding/save",

    /**
     * *教师考勤
     * GET_SCHOOL_CHECK_ATTENDANCE_RECORD_LIST 考勤明细列表
     * GET_CONDITION_LIST_LEAVE 获取离职人员
     * ATTENDANCE_DETAILS_IMPORT 导入
     *
     *
     * */
    GET_SCHOOL_CHECK_ATTENDANCE_RECORD_LIST:
        BASE_URL + "/school/schoolCheckAttendanceRecord/list",
    GET_CONDITION_LIST_LEAVE:
        BASE_URL + "/school/schoolTeacher/getLeaveTeacherList",
    ATTENDANCE_DETAILS_IMPORT:
        BASE_URL + "/school/schoolCheckAttendanceRecord/upload",

    /**
     * 用户管理页面
     * GET_DEPARTMENT_LIST 所属部门
     * GET_SUBJECT_LIST 获取任教科目
     * GET_ALL_DEPARTMENT_LIST 获取树形所有部门
     * GET_CONDITION_LIST 角色下拉列表
     * SET_SWITCHING_USER_STATUS 修改用户状态
     * GET_SCHOOL_USER_INFO 用户详情接口
     * GET_TEACHER_INFO 获取教师详情
     * */
    GET_DEPARTMENT_LIST: BASE_URL + "/school/schoolOrgan/listByCondition",
    GET_SUBJECT_LIST: BASE_URL + "/school/schoolDictValue/getSubjectList",
    GET_ALL_DEPARTMENT_LIST:
        BASE_URL + "/school/schoolOrgan/getDepartmentOrgan",
    GET_CONDITION_LIST: BASE_URL + "/school/schoolRole/listByCondition",
    SET_SWITCHING_USER_STATUS: BASE_URL + "/school/schoolUser/updateStatus",
    GET_SCHOOL_USER_INFO: BASE_URL + "/school/schoolUser/info",
    /**
     * 职工档案/学生档案 导入
     * SELECT_FILE_CATEGORY 选择档案类别
     *
     * */
    SELECT_FILE_CATEGORY:
        BASE_URL + "/school/schoolArchivesType/listByCondition",
    SCHOOL_ARCHIVES_DESIGN: BASE_URL + "/school/schoolArchivesDesign/import",
    GET_DESIGN_LIST: BASE_URL + "/school/schoolArchivesDesign/getDesignList",

    /**
     * 校园公告
     * GET_CAMPUS_ANNOUNCEMENT_DETAIL 校园公告详情
     * LIST_OF_REVIEWS_ON_THE_CAMPUS_SIDE 校园评论列表
     * CAMPUS_COMMENTS_TOP 校园端评论置顶/取消置顶
     * CAMPUS_COMMENTS_DELETE 校园端删除评论
     * */
    GET_CAMPUS_ANNOUNCEMENT_DETAIL: BASE_URL + "/operate/operateNotice/getInfo",
    LIST_OF_REVIEWS_ON_THE_CAMPUS_SIDE:
        BASE_URL + "/school/schoolNotice/commentList",
    CAMPUS_COMMENTS_TOP: BASE_URL + "/school/schoolNotice/commentTop",
    CAMPUS_COMMENTS_DELETE: BASE_URL + "/school/schoolNotice/commentDelete",

    // 考勤明细导入
    UPLOAD_CHECK_TEACHER:
        BASE_URL + "/school/schoolCheckAttendanceRecord/upload",

    UPLOAD_MORAL_STUDENT: BASE_URL + '/school/schoolMoralStudentDetail/upload',

    /**
     * 报修系统
     * CATEGORY_MANAGE_DELETE 报修门类管理-删除
     * */
   CATEGORY_MANAGE_DELETE: BASE_URL + '/school/repairType/delete',

    /**
     * 假期管理
     * GET_VACATION_SEARCH_LIST 假期管理列表
     * SAVE_SCHOOL_VACATION 新增/编辑假期
     * DELETE_SCHOOL_VACATION 删除假期
     * GET_UNDELETED_LIST 假期管理列表查询（不分页）
     * GET_LEAVE_HOURS 获取请假/调休套件时长
     * GET_LEAVE_HOURS_HANDLE_CANCEL 手动销假时获取请假时长
     * HANDLE_CANCEL_LEAVE 手动销假
     * */
    GET_VACATION_SEARCH_LIST: BASE_URL + '/oa/schoolVacation/list',
    SAVE_SCHOOL_VACATION: BASE_URL + '/oa/schoolVacation/save',
    DELETE_SCHOOL_VACATION: BASE_URL + '/oa/schoolVacation/delete',
    GET_UNDELETED_LIST: BASE_URL + '/oa/schoolVacation/listByCondition',
    GET_LEAVE_HOURS: BASE_URL + '/oa/schoolProcessApply/getVacationDuration',
    GET_LEAVE_HOURS_HANDLE_CANCEL: BASE_URL + '/oa/schoolProcessApply/getRealDuration',
    HANDLE_CANCEL_LEAVE: BASE_URL + '/oa/schoolProcessApply/handCancelLeave',
    UPDATE_SCHOOL_VACATION_STATUS: BASE_URL + '/oa/schoolVacation/updateStatus',
    /**
     * 退出登录
     * AUTH_SC_LOGOUT
     */
    AUTH_SC_LOGOUT: BASE_URL + "/auth/sc/logout",

    /**
     * 通用
     * 第三方登录
     * AUTO_LOGIN_SCHOOL_FROM_OLD_OPERATE 霸州一中老云校
     * */
    AUTO_LOGIN_SCHOOL_FROM_OLD_OPERATE: BASE_URL + "/auth/sc/autoLoginSchoolFromOldOperate",
     /** * 通用
     * GET_CLASS_DROP_DOWN_LIST 班级下拉列表
     * GET_CLASS_DROP_DOWN_LIST2 班级下拉列表-无权限
	 * GET_YEAR_DROP_DOWN_LIST 年级下拉列表
	 * GET_YEAR_DROP_DOWN_LIST2 年级下拉列表-无权限
     * 托管服务
     * HOSTING_SERVICES_LIST 托管服务列表
     * HOSTING_SERVICES_SAVE 托管服务保存
     * HOSTING_SERVICES_DEL 托管服务删除
     * HOSTING_SERVICES_CHECK_STU 托管服务是否存在学生
     * GET_CLASSES_LIST 获取班级列表
     * - 托管学生
     * GET_DEPOSIT_STUDENT_LIST 托管学生列表
     *  ADD_HOSTING_STU 添加托管学生
     *  IMPORT_HOSTING_STU 导入托管学生
     *  DOWNLOAD_EXCEL_ERROR 下载错误数据
     * DOWNLOAD_EXCEL_MODEL 下载模板
     * DELETE_HOSTING_STU 删除托管学生
     * GET_STUDENT_LIST 获取学生下拉列表
     * - 检查记录
     * 按天
     * GET_CHECK_RECORD_DATA_LIST 检查记录按天列表
     * GET_CHECK_RECORD_DATA_DELETE 检查记录按天删除
     * GET_CHECK_RECORD_DATA_DETAIL 检查记录按天详情
     * GET_CHECK_RECORD_DATA_SAVE 检查记录按天保存
     * GET_CHECK_RECORD_LIST 检查记录按天导入
     * 按学生
     * GET_CHECK_RECORD_STUDENT_LIST 检查记录按学生列表
     * GET_CHECK_RECORD_STUDENT_DETAIL 检查记录按学生详情
     * GET_ALL_DAY 获取总托管天数
     * 
     * 就餐服务
     * HOSTING_SERVICES_LIST2 就餐服务列表
     * HOSTING_SERVICES_SAVE2 就餐服务保存
     * HOSTING_SERVICES_DEL2 就餐服务删除
     * HOSTING_SERVICES_CHECK_STU2 就餐服务是否存在学生
     * - 就餐学生
     * GET_DEPOSIT_STUDENT_LIST2 就餐学生列表
     * GET_STUDENT_LIST2 获取学生下拉列表
     * ADD_HOSTING_STU2 添加就餐学生
     * DELETE_HOSTING_STU2 删除就餐学生
     * DOWNLOAD_EXCEL_MODEL2 下载模板
     * IMPORT_HOSTING_STU2 导入就餐学生
     * -就餐账单
     * IMPORT_DEPOSITUPLOAD2 导入就餐账单
     * - 检查记录
     * 按天
     * GET_CHECK_RECORD_DATA_LIST2 检查记录按天列表
     * GET_CHECK_RECORD_DATA_DELETE2 检查记录按天删除
     * GET_CHECK_RECORD_DATA_DETAIL2 检查记录按天详情
     * GET_CHECK_RECORD_LIST2 检查记录按天导入
     * GET_CHECK_RECORD_DATA_SAVE2 检查记录按天保存
     * 按学生
     * GET_CHECK_RECORD_STUDENT_LIST2 检查记录按学生列表
     * GET_ALL_DAY2 获取总托管天数
     * GET_CHECK_RECORD_STUDENT_DETAIL2 检查记录按学生详情
     * */
    GET_CLASS_DROP_DOWN_LIST: BASE_URL + '/school/schoolOrgan/getClassListByTeacherId',
    GET_CLASS_DROP_DOWN_LIST2: BASE_URL + '/school/schoolOrgan/getClassByGradeId',
	GET_YEAR_DROP_DOWN_LIST: BASE_URL + '/school/schoolOrgan/getGradeListByTeacherId',
	GET_YEAR_DROP_DOWN_LIST2: BASE_URL + '/school/schoolOrgan/listByConditionNoAuth',
    HOSTING_SERVICES_LIST: BASE_URL + "/school/schoolDeposit/list",
    HOSTING_SERVICES_SAVE: BASE_URL + "/school/schoolDeposit/save",
    HOSTING_SERVICES_DEL: BASE_URL + "/school/schoolDeposit/delete",
    HOSTING_SERVICES_CHECK_STU: BASE_URL + "/school/schoolDeposit/checkStudentById",
    ADD_HOSTING_STU: BASE_URL + "/school/depositStudent/save",
    IMPORT_HOSTING_STU: BASE_URL + "/school/depositStudent/import",
    DOWNLOAD_EXCEL_ERROR: BASE_URL + "/school/depositStudent/downloadError",
    GET_DEPOSIT_STUDENT_LIST: BASE_URL + "/school/depositStudent/lit4page",
    GET_CLASSES_LIST: BASE_URL + "/school/schoolOrgan/getOrganByOperate",
    DOWNLOAD_EXCEL_MODEL: BASE_URL + "/school/depositStudent/template",
    DELETE_HOSTING_STU: BASE_URL + "/school/depositStudent/delete",
    GET_CHECK_RECORD_DATA_LIST: BASE_URL + "/school/schoolCheckRecord/list",
    GET_STUDENT_LIST:BASE_URL + "/school/depositStudent/getNotCheckStudent",
    GET_CHECK_RECORD_STUDENT_LIST: BASE_URL + "/school/schoolCheckStudent/list",
    GET_ALL_DAY: BASE_URL + "/school/schoolCheckStudent/getAllActualDay",
    GET_CHECK_RECORD_STUDENT_DETAIL: BASE_URL + "/school/schoolCheckStudent/getDetail",
    GET_CHECK_RECORD_DATA_DELETE: BASE_URL + "/school/schoolCheckRecord/delete",
    GET_CHECK_RECORD_DATA_DETAIL: BASE_URL + "/school/schoolCheckRecord/getStudentByDepositId",
    GET_CHECK_RECORD_DATA_SAVE: BASE_URL + "/school/schoolCheckRecord/save",
    GET_CHECK_RECORD_LIST: BASE_URL + "/school/schoolCheckRecord/getStudentInfo",
    IMPORT_DEPOSITUPLOAD: BASE_URL + "/school/schoolDeposit/upload",

    HOSTING_SERVICES_LIST2: BASE_URL + "/school/schoolRepast/list",
    HOSTING_SERVICES_SAVE2: BASE_URL + "/school/schoolRepast/save",
    HOSTING_SERVICES_DEL2: BASE_URL + "/school/schoolRepast/delete",
    HOSTING_SERVICES_CHECK_STU2: BASE_URL + "/school/schoolRepast/checkStudentById",
    GET_DEPOSIT_STUDENT_LIST2: BASE_URL + "/school/repastStudent/lit4page",
    GET_STUDENT_LIST2:BASE_URL + "/school/repastStudent/getNotCheckStudent",
    ADD_HOSTING_STU2: BASE_URL + "/school/repastStudent/save",
    DELETE_HOSTING_STU2: BASE_URL + "/school/repastStudent/delete",
    DOWNLOAD_EXCEL_MODEL2: BASE_URL + "/school/repastStudent/template",
    IMPORT_HOSTING_STU2: BASE_URL + "/school/repastStudent/import",
    IMPORT_DEPOSITUPLOAD2: BASE_URL + "/school/schoolRepast/upload",
    GET_CHECK_RECORD_DATA_LIST2: BASE_URL + "/school/schoolRepastCheckRecord/list",
    GET_CHECK_RECORD_DATA_DELETE2: BASE_URL + "/school/schoolRepastCheckRecord/delete",
    GET_CHECK_RECORD_STUDENT_LIST2: BASE_URL + "/school/schoolRepastCheckStudent/list",
    GET_ALL_DAY2: BASE_URL + "/school/schoolRepastCheckStudent/getAllActualDay",
    GET_CHECK_RECORD_DATA_DETAIL2: BASE_URL + "/school/schoolRepastCheckRecord/getStudentByDepositId",
    GET_CHECK_RECORD_LIST2: BASE_URL + "/school/schoolRepastCheckRecord/getStudentInfo",
    GET_CHECK_RECORD_DATA_SAVE2: BASE_URL + "/school/schoolRepastCheckRecord/save",
    GET_CHECK_RECORD_STUDENT_DETAIL2: BASE_URL + "/school/schoolRepastCheckStudent/getDetail",

    /**
     * 薪酬管理
     *  薪酬项目
     *  GET_COMPENSATION_ITEMS 薪酬项目列表
     *  GET_COMPENSATION_TYPE 获取薪酬类型
     *  CREATING_COMPENSATION_PROGRAMS 创建薪酬项目
     *  SET_COMPENSATION_ITEMS_EMPTY 列表清空
     *  CHECK_UPLOAD_RECORD 查看上传记录
     *  DEL_COMPENSATION_ITEMS 删除薪酬项目
     *      薪酬类型管理
     *
     * GET_SALARY_PROJECT_LIST  薪酬类型管理列表页面
     * GET_SALARY_PROJECT_DELETE 薪酬类型管理-删除
     * SET_SWITCHING_STATUS 更新状态
     *
    **/
    GET_COMPENSATION_ITEMS: BASE_URL + "/school/schoolSalaryProject/list",
    GET_COMPENSATION_TYPE: BASE_URL + "/school/schoolDictValue/listByCondition",
    CREATING_COMPENSATION_PROGRAMS: BASE_URL + "/school/schoolSalaryProject/save",
    SET_COMPENSATION_ITEMS_EMPTY: BASE_URL + "/school/schoolSalaryProject/clearInfo",
    CHECK_UPLOAD_RECORD: BASE_URL + "/school/salaryUploadRecord/page",
    DEL_COMPENSATION_ITEMS: BASE_URL + "/school/schoolSalaryProject/delete",
    GET_SALARY_PROJECT_LIST:BASE_URL + "/school/schoolDictValue/salaryList",
    GET_SALARY_PROJECT_DELETE:BASE_URL + "/school/schoolDictValue/salaryDelete",
    SET_SWITCHING_STATUS:BASE_URL + "/school/schoolDictValue/updateState",

    /**
     *   薪酬项目详情
     * GET_SALARY_PROJECT_DETAIL_LIST 薪酬项目详情
     * GET_SALARY_PROJECT_DETAIL_DELETE 薪酬项目详情-删除
     * GET_SALARY_PROJECT_DETAIL_ISSUE 薪酬项目详情-薪酬发放
     * GET_SALARY_PROJECT_DETAIL_EDIT 薪酬项目详情-编辑
    **/
    GET_SALARY_PROJECT_DETAIL_LIST:BASE_URL + "/school/salaryTeacher/list",
    GET_SALARY_PROJECT_DETAIL_DELETE:BASE_URL + "/school/salaryTeacher/delete",
    GET_SALARY_PROJECT_DETAIL_ISSUE:BASE_URL + "/school/salaryTeacher/payOffInfo",
    GET_SALARY_PROJECT_DETAIL_EDIT:BASE_URL + "/school/salaryTeacher/edit",

    /**
     * 薪酬查询
     * GET_QUERY_TEACHER_SALARY_LIST    薪酬查询-列表
    **/
    GET_QUERY_TEACHER_SALARY_LIST:BASE_URL + "/school/salaryTeacher/queryTeacherSalaryList",

    /**
     * 薪酬分析
     * GET_PEOPLE_INFO 人员信息列表
     * GET_TOTAL_AVERAGE 总计和月人均接口
     * GET_DOWNLOAD_STATISTICS 下载统计
     * GET_ANALYSIS_DETAIL 教师薪酬分析
     * */
    GET_PEOPLE_INFO: BASE_URL + "/school/salaryAnalysis/salaryAnalysis",
    GET_TOTAL_AVERAGE: BASE_URL + "/school/salaryAnalysis/totalAnalysis",
    GET_DOWNLOAD_STATISTICS: BASE_URL + "/school/salaryAnalysis/export",
    GET_ANALYSIS_DETAIL: BASE_URL + "/school/salaryAnalysis/getAnalysisDetail",

    /**
     * 趋势分析
     * GET_TREND_ANALYSIS 趋势分析查询
     * */
    GET_TREND_ANALYSIS: BASE_URL + "/school/salaryTeacher/analyse",

    /**
     * 视频监控
     * GET_EQUIPMENT_LIST 获取设备列表
     * GET_GROUP_LIST 获取分组列表
     * GET_CLASS_LIST 获取班级
     * EDIT_DEVICE 编辑分组和班级和部门权限
     * SET_BATCH_GROUP 设置分组
     * SET_BATCH_DEBT 批量设置部门权限
     *
     * */
    GET_EQUIPMENT_LIST: BASE_URL + "/school/schoolVideoMonitoringEquipment/list",
    GET_GROUP_LIST: BASE_URL + "/school/schoolVideoMonitoringEquipment/groupList",
    SYNCHRONIZATION_DEVICE: BASE_URL + "/school/schoolVideoMonitoringEquipment/synchronizationDevice",
    GET_CLASS_LIST: BASE_URL + "/operate/common/getSchoolOrgan",
    SCHOOL_VIDEO_MONITORING_EQUIPMENT_SAVE: BASE_URL + "/school/schoolVideoMonitoringEquipment/save",
    EDIT_DEVICE: BASE_URL + "/school/schoolVideoMonitoringEquipment/editDevice",
    SET_BATCH_GROUP: BASE_URL + "/school/schoolVideoMonitoringEquipment/batchGroup",
    SET_BATCH_DEBT: BASE_URL + "/school/schoolVideoMonitoringEquipment/batchDebtSet",
    GET_ECHO_DATA: BASE_URL + "/school/schoolVideoMonitoringEquipment/dataEcho",

    /**
     *  职工档案
     *  GET_USER_PREFERENCES 获取用户偏好设置
     *  SAVE_USER_PREFERENCES 保存用户偏好设置
    */

   GET_USER_PREFERENCES: BASE_URL + "/school/schoolUserPreferenceDynamicConfig/getDetail",
   SAVE_USER_PREFERENCES: BASE_URL + "/school/schoolUserPreferenceDynamicConfig/save",

    /**
     * 考勤排班
     * CAMPUS_SAFETY_SAVE_SETTING 考勤设置保存接口
     * CAMPUS_SAFETY_GET_SETTING  考勤设置查询接口（回显）
     **/
    CAMPUS_SAFETY_SAVE_SETTING: BASE_URL + "/school/dormitoryTimeSetting/saveSetting",
    CAMPUS_SAFETY_GET_SETTING: BASE_URL + "/school/dormitoryTimeSetting/listByCondition",

   /**
    * 学生量化
    * MORAL_DISPOSE_TYPE_LIST 处理方法
    * ADD_PROCESSING_RECORDS 教师新增处理记录
    * PROCESSING_RECORD_LIST_QUERY 查看记录详情列表
    * DELETE_PROCESSING_RECORDS 删除记录列表
    * */
   MORAL_DISPOSE_TYPE_LIST:  BASE_URL + "/school/schoolDictValue/listMoralDisposeType",
   ADD_PROCESSING_RECORDS:  BASE_URL + '/school/schoolMoralStudentDetailHandle/save',
   PROCESSING_RECORD_LIST_QUERY:  BASE_URL + '/school/schoolMoralStudentDetailHandle/listNoPage',
   DELETE_PROCESSING_RECORDS: BASE_URL + "/school/schoolMoralStudentDetailHandle/deleteOne",

   /**
    * 年级量化
    * GET_GRADEDATE_DATE_RULE_LIST 获取年级量化列表
    * GET_STU_QUANTIZATION_DETAIL_BY_GRADE 获取学生量化详情
    * GRADE_DATE_RULE_EXPORT 导出年级量化列表
    *
    * **/
   GET_GRADEDATE_DATE_RULE_LIST: BASE_URL + "/school/schoolMoralGradeDateRule/list",
   GET_STU_QUANTIZATION_DETAIL_BY_GRADE: BASE_URL + "/school/schoolMoralGradeDateRule/getStuQuantizationDetailByGrade",
   GRADE_DATE_RULE_EXPORT: BASE_URL + "/school/schoolMoralGradeDateRule/export",

    /**
     * 衡水十四中usbKey对接，单点登录
     * */
    AUTO_LOGIN_SCHOOL_FROM_USB_KEY: BASE_URL + "/auth/sc/loginSchoolUSB",

   /**
    *  宿舍考核
    *  GET_ROOM_MORAL_LIST 宿舍考核列表
    *  GET_ALL_DORMOTORY_ROOM_LIST 获取宿舍条件筛选列表
    *  DORMOTORY_ROOM_RULE 考核指标列表
    *  DORMOTORY_ATTENDANCE_GET_ROOM_INFO 获取宿舍房间详情-包含人员信息
    *  SAVE_DORMOTORY_ROOM_DETAIL_INFO 宿舍考核保存接口
    *  UPDATE_DORMOTORY_ROOM_DETAIL_INFO 宿舍考核修改接口
    *  SAVE_DORMOTORY_MORAL_RULE 新增、编辑宿舍考核指标
    *  DELETE_DORMOTORY_MORAL_RULE 删除指标接口
    *  GET_DORMOTORY_MORAL_REPORT 宿舍报表列表
    *  EXPORT_DORMOTORY_MORAL_REPORT 宿舍报表导出
    *  GET_DORMOTORY_MORAL_BED_NUM_STATUS 获取宿舍分配状态
    *  GET_LEVEL_STUDENTS 获取当前宿舍下的学生和能否考核的状态
   */

  GET_ROOM_MORAL_LIST: BASE_URL + "/school/schoolDormitoryRoom/getRoomMoralList",
  GET_ALL_DORMOTORY_ROOM_LIST: BASE_URL + "/school/schoolDormitoryRoom/getAllList",
  DORMOTORY_ROOM_RULE: BASE_URL + "/school/schoolMoralRule/listByCondition",
  DORMOTORY_ATTENDANCE_GET_ROOM_INFO: BASE_URL + "/school/dormitoryAttendance/getRoomInfo",
  SAVE_DORMOTORY_ROOM_DETAIL_INFO: BASE_URL + "/school/schoolMoralStudentDetail/saveRoomMoralDetailInfo",
  SAVE_DORMOTORY_MORAL_RULE: BASE_URL + "/school/schoolMoralRule/save",
  DELETE_DORMOTORY_MORAL_RULE: BASE_URL + "/school/schoolMoralRule/delete",
  GET_DORMOTORY_MORAL_REPORT: BASE_URL + "/school/schoolMoralStudentDetail/getRoomMoralReport",
  EXPORT_DORMOTORY_MORAL_REPORT: BASE_URL + "/school/schoolMoralStudentDetail/getRoomMoralReportExport",
  GET_DORMOTORY_MORAL_BED_NUM_STATUS: BASE_URL + "/school/schoolDormitoryDistribution/getBedNumStatus",
  UPDATE_DORMOTORY_ROOM_DETAIL_INFO: BASE_URL + "/school/schoolMoralStudentDetail/updateStudentMoral",
  GET_LEVEL_STUDENTS: BASE_URL + "/school/schoolMoralStudentDetail/verifyDorm",
/**
    *  员工考勤
    *  GET_ATTENDANCE_EARLY_LATE_INTERVAL 查询考勤规则早退迟到区间时间
    *  MODIFY_ATTENDANCE_ADMINISTARATORS 缺卡考勤管理员迟到早退修改为正常打卡记录
    *  ADD_ATTENDANCE_ADMINISTARATORS 缺卡考勤管理员添加打卡记录
   */
    GET_ATTENDANCE_EARLY_LATE_INTERVAL: BASE_URL + "/school/schoolCheckAttendanceRule/attendanceEarlyLateInterval",
    ADD_ATTENDANCE_ADMINISTARATORS: BASE_URL + "/school/schoolCheckAttendanceRecord/addAttendanceAdministrators",
    MODIFY_ATTENDANCE_ADMINISTARATORS: BASE_URL + "/school/schoolCheckAttendanceRecord/modifyAttendanceAdministrators",
    /*
        GET_GROUP_SELECT_TREELIST 公共来源下拉列表
        GET_EDUCATION_BUREAU_RECEIVE_LIST 我的收文列表接口
        GET_EDUCATION_BUREAU_RECEIVE_ISREAD 更改文件状态
        GET_EDUCATION_BUREAU_RECEIVE_DELEGATE 公文委派
        EDUCATION_BUREAU_RECEIVE_GET_DETAIL_BYID 公文详情

    */
    GET_GROUP_SELECT_TREELIST: BASE_URL + "/operate/operateSchool/getGroupSelectTreeList",
    GET_EDUCATION_BUREAU_RECEIVE_LIST: BASE_URL + "/educationBureau/educationBureauReceive/list",
    GET_EDUCATION_BUREAU_RECEIVE_ISREAD: BASE_URL + "/educationBureau/educationBureauReceive/isRead",
    GET_EDUCATION_BUREAU_RECEIVE_DELEGATE: BASE_URL + "/educationBureau/educationBureauReceive/delegate",
    EDUCATION_BUREAU_RECEIVE_GET_DETAIL_BYID: BASE_URL + "/educationBureau/educationBureauReceive/getDetailById",
    GET_EDUCATION_BUREAU_RECEIVE__FEEDBACK: BASE_URL + "/educationBureau/educationBureauReceive/feedBack",
    EDUCATION_BUREAU_RECEIVE_UPDATE_RECEIVE_PAPER:BASE_URL + "/educationBureau/educationBureauReceive/updateReceivePaper",
    /*
        EDUCATION_BUREAU_DIRECTORS_LIST 负责人列表
        EDUCATION_BUREAU_DIRECTORS_SAVE 新增负责人
        设置当前负责人
    */
    EDUCATION_BUREAU_DIRECTORS_LIST: BASE_URL + "/educationBureau/educationBureauDirectors/list",
    EDUCATION_BUREAU_DIRECTORS_SAVE: BASE_URL + '/educationBureau/educationBureauDirectors/save',
    EDUCATION_BUREAU_DIRECTORS_SAVE_RECEIVER: BASE_URL + "/educationBureau/educationBureauDirectors/saveReceiver",
     /*
        /operate/common/getDictList 查询假期常用名称接口
    */
    OPERATE_COMMON_GETDICTLIST: BASE_URL + "/operate/common/getDictList",
    OA_SCHOOL_VACATION_INFO: BASE_URL + '/oa/schoolVacation/info/',
    GET_LIST_BY_FORMIDS: BASE_URL + "/oa/schoolFormRelation/getListByFormIds",
    GET_LIST_BY_FORMIDS1: BASE_URL + "/oa/schoolFormRelation/getListByFormIds1",

    /**
    *  请假记录
    *  GET_SCHOOL_TEACHER_LEAVE_RECORD_LIST 获取请假记录列表
    *  GET_SCHOOL_TEACHER_LEAVE_RECORD_INFO 获取请假记录详情
    *  UPLOAD_SCHOOL_TEACHER_LEAVE_RECORD 批量导入请假记录
    *  DELETE_SCHOOL_TEACHER_LEAVE_RECORD 批量导入的单个删除接口
    *  UPDATE_SCHOOL_TEACHER_LEAVE_RECORD 批量导入的单个修改接口
    *  EXPORT_SCHOOL_TEACHER_LEAVE_RECORD 批量导出请假记录
    *  GET_TEACHER_VACATION_DURATION  获取时长接口
    *  TEACHER_CHECK_APPLY_DATE       校验时间是否有冲突
    *
   */
    GET_SCHOOL_TEACHER_LEAVE_RECORD_LIST: BASE_URL + "/school/schoolTeacherLeaveRecord/list",
    GET_SCHOOL_TEACHER_LEAVE_RECORD_INFO: BASE_URL + "/school/schoolTeacherLeaveRecord/info",
    UPLOAD_SCHOOL_TEACHER_LEAVE_RECORD: BASE_URL + "/school/schoolTeacherLeaveRecord/upload",
    DELETE_SCHOOL_TEACHER_LEAVE_RECORD: BASE_URL + "/school/schoolTeacherLeaveRecord/delete",
    UPDATE_SCHOOL_TEACHER_LEAVE_UPDATE: BASE_URL + "/school/schoolTeacherLeaveRecord/update",
    EXPORT_SCHOOL_TEACHER_LEAVE_EXPORT: BASE_URL + "/school/schoolTeacherLeaveRecord/export",
    GET_TEACHER_VACATION_DURATION: BASE_URL + "/oa/schoolProcessApply/getTeacherVacationDuration",
    TEACHER_CHECK_APPLY_DATE: BASE_URL + "/oa/schoolProcessApply/teacherCheckApplyDate",

    /**
     * 教师放假相关
     * SAVE_TEACHER_FURLOUGH 新增、编辑放假
     * GET_TEACHER_FURLOUGH_LIST 获取放假列表
     * GET_TEACHER_FURLOUGH_INFO 获取放假详情
     * DELETE_TEACHER_FURLOUGH 删除放假设置
     * VERIFY_TEACHER_FURLOUGH_REPEAT 验证放假人员是否重复
     * IS_OPEN_CLOCKIN 判断是否开启了缺卡设置
     */
    SAVE_TEACHER_FURLOUGH: BASE_URL + "/school/schoolTeacherFurlough/save",
    GET_TEACHER_FURLOUGH_LIST: BASE_URL + "/school/schoolTeacherFurlough/list",
    GET_TEACHER_FURLOUGH_INFO: BASE_URL + "/school/schoolTeacherFurlough/info",
    DELETE_TEACHER_FURLOUGH: BASE_URL + "/school/schoolTeacherFurlough/delete",
    VERIFY_TEACHER_FURLOUGH_REPEAT: BASE_URL + "/school/schoolTeacherFurlough/verifyData",
    IS_OPEN_CLOCKIN: BASE_URL + "/school/schoolAutoRepair/checkAutoRepairSetting",
    IS_OPEN_FURLOUGH: BASE_URL + "/school/schoolTeacherFurlough/checkTeacherFurloughSetting",
    /**
     * 家长管理
     * IMPORT_PARENT_INFO 导入家长
     * GET_TREE_BY_SCHOOL_ID 获取班级
     * GET_STU_LIST_BY_ORGAN_ID 获取学生
     */
     IMPORT_PARENT_INFO: BASE_URL + "/school/schoolParentInfo/importParentInfo",
     GET_TREE_BY_SCHOOL_ID: BASE_URL + "/school/schoolOrgan/getTreeBySchoolId",
     GET_STU_LIST_BY_ORGAN_ID: BASE_URL + "/school/schoolOrgan/getStuListByOrganId",
     GET_ORGAN_TREE_BY_SCHOOL_ID: BASE_URL + "/school/schoolOrgan/getOrganTreeBySchoolId",
    /**
     * 套餐订单
     * GET_SCHOOL_SET_MEAL_ORDER_LIST 获取订单列表
     * EXPORT_SCHOOL_SET_MEAL_ORDER 导出订单
     */
    GET_SCHOOL_SET_MEAL_ORDER_LIST: BASE_URL + "/consume/schoolSetMealOrder/list",
    EXPORT_SCHOOL_SET_MEAL_ORDER: BASE_URL + "/consume/schoolSetMealOrder/export",

    /**
    *  德育督导
    *  RESTORE_DEFAULT_SETTING 德育督导恢复默认
    *
   */
    RESTORE_DEFAULT_SETTING: BASE_URL + "/school/schoolUserPreferenceDynamicConfig/restoreDefaultSetting",

    /**
     * 登录页管理
     * GET_LOGIN_PAGE_TEMPLATE_LIST 获取登录页模板列表
     * SET_LOGIN_PAGE_TEMPLATE_ENABLED 启用登录页模板
     * GET_LOGIN_PAGE_TEMPLATE_DETAIL 获取登录页模板详情
     * EDIT_LOGIN_PAGE_TEMPLATE 编辑登录页模板
     * COPY_LOGIN_PAGE_TEMPLATE 复制登录页模板
     * DELETE_LOGIN_PAGE_TEMPLATE 删除登录页模板
     */
    GET_LOGIN_PAGE_TEMPLATE_LIST: BASE_URL + "/school/schoolTemplateConfig/getTemplateList",
    SET_LOGIN_PAGE_TEMPLATE_ENABLED: BASE_URL + "/school/schoolTemplateConfig/enabled",
    GET_LOGIN_PAGE_TEMPLATE_DETAIL: BASE_URL + "/school/schoolTemplateConfig/getTemplateById",
    EDIT_LOGIN_PAGE_TEMPLATE: BASE_URL + "/school/schoolTemplateConfig/editTemplate",
    COPY_LOGIN_PAGE_TEMPLATE: BASE_URL + "/school/schoolTemplateConfig/copyTemplate",
    DELETE_LOGIN_PAGE_TEMPLATE: BASE_URL + "/school/schoolTemplateConfig/deleteById",
    /**
     * 登录页模板
     * GET_MATERIAL_LIBRARY_CATEGORY 获取素材库素材分组
     * GET_MATERIAL_LIBRARY_LIST 获取素材库素材列表
     */
    GET_MATERIAL_LIBRARY_CATEGORY: BASE_URL + "/school/schoolTemplateConfig/getMaterialCategory",
    GET_MATERIAL_LIBRARY_LIST: BASE_URL + "/school/schoolTemplateConfig/getMaterialLibrary",
    /**
     * 职工档案导入
     */
    TEACHER_UPDATE_IMPORT: BASE_URL + "/operate/operateTeacher/teacherUpdateImport",
    /**
     * 出入统计
     * GET_OUT_IN_LIST_DATA 获取出入统计列表
     * GET_OUT_IN_COUNT_DATA 获取总计信息
     */
    GET_OUT_IN_LIST_DATA: BASE_URL + "/accessSchool/accessRecord/schoolAccessStatistics",
    GET_OUT_IN_COUNT_DATA: BASE_URL + "/accessSchool/accessRecord/schoolAccessTotalSum",

    /**
     * 考试项目
     * GET_EXAM_SCORE_LIST 获取考试详情列表
     * GET_EXAM_SCORE_SETTING_TABLE_HEAD_LIST 获取考试详情动态表头
     * GET_GRADE_LIST_NO_AUTH 获取年级（无权限）
     * GET_CLASS_LIST_NO_AUTH 获取班级（无权限）
     */
    GET_EXAM_SCORE_LIST:
        BASE_URL + "/school/schoolExam/getExamScoreList",
    GET_EXAM_SCORE_SETTING_TABLE_HEAD_LIST:
        BASE_URL + "/school/schoolExamScoreSetting/getSchoolExamScoreTableHead",
    GET_GRADE_LIST_NO_AUTH:
		BASE_URL + "/school/schoolOrgan/getGradeListNoAuth",
	GET_CLASS_LIST_NO_AUTH:
		BASE_URL + "/school/schoolOrgan/getClassListNoAuth",


    /**
     * 一卡通对账
     * GET_BILL_LIST 获取账单列表
     * GET_BILL_STATISTICS 获取账单统计
     * GET_CHECKING_CONFIG 获取对账设置
     * SAVE_CHECKING_CONFIG 保存对账设置
     */
    GET_BILL_LIST:
        BASE_URL + "/consume/yktOrder/findYktOrderCheckAccountsInfo",
    GET_BILL_STATISTICS:
        BASE_URL + "/consume/yktOrder/findYktReconciliationDataSummation",
    GET_BILL_STATISTICS_DETAIL:
        BASE_URL + "/consume/yktOrder/findYktReconciliationDataSummationdetails",
    GET_CHECKING_CONFIG:
        BASE_URL + "/school/schoolyktreconciliationconfig/findSchoolYktReconciliationConfig",
    SAVE_CHECKING_CONFIG:
        BASE_URL + "/school/schoolyktreconciliationconfig/save",
    /**
     * 学生架构
     * IMPORT_ORGAN_INFO 导入学生架构
    */
    IMPORT_ORGAN_INFO: BASE_URL + "/school/schoolOrgan/importOrganInfo",
    /**
     * 信息采集
     * GET_SCHOOL_COLLECT_FORM_LIST 信息采集列表查询
     * RELEASE_SCHOOL_COLLECT_FORM 信息采集发布
     * DELAYED_SCHOOL_COLLECT_FORM 信息采集表单延时发布
     * DELETE_SCHOOL_COLLECT_FORM 信息采集删除表单
     * FINISH_SCHOOL_COLLECT_FORM 信息采集表单结束
    */
    GET_SCHOOL_COLLECT_FORM_LIST: BASE_URL + '/school/schoolCollectForm/list',
    RELEASE_SCHOOL_COLLECT_FORM: BASE_URL + '/school/schoolCollectForm/release',
    DELAYED_SCHOOL_COLLECT_FORM: BASE_URL + '/school/schoolCollectForm/delayed',
    GET_SCHOOL_COLLECT_FORM_INFO: BASE_URL + '/school/schoolCollectForm/info/',
    DELETE_SCHOOL_COLLECT_FORM: BASE_URL + '/school/schoolCollectForm/delete',
    FINISH_SCHOOL_COLLECT_FORM: BASE_URL + '/school/schoolCollectForm/finish',
    /**
     * 信息采集
     * GET_SCHOOL_COLLECT_RECORD_LIST 用户填写情况列表
     * UPDATE_SCHOOL_COLLECT_STATUS 标为有效无效答卷
     * GET_SCHOOL_COLLECT_ANWER  查看答案
     * GET_SCHOOL_COLLECT_TEACHER_STATUSTICS_LIST 被评价教师统计列表\
     * GET_SCHOOL_COLLECT_DETAILS_LIST 用户填写详情列表
     *
    */
    GET_SCHOOL_COLLECT_RECORD_LIST: BASE_URL + '/school/schoolCollectRecord/list',
    GET_SCHOOL_COLLECT_TEACHER_STATISTICS_LIST: BASE_URL + '/school/schoolCollectTeacherStatistics/list',
    GET_SCHOOL_COLLECT_DETAILS_LIST: BASE_URL + '/school/schoolCollectDetails/list',
    GET_SCHOOL_COLLECT_RECORD_LIST: BASE_URL + '/school/schoolCollectRecord/list',
    UPDATE_SCHOOL_COLLECT_STATUS: BASE_URL + '/school/schoolCollectRecord/updateStatus',
    GET_SCHOOL_COLLECT_ANWER: BASE_URL + '/school/schoolCollectRecord/info/',


    /**
     * 整体结果
     * GET_SCHOOL_ZHENG_LIST 统计-整体结果-查看作答情况
     * GET_SCHOOL_ZHENG_XQ 统计-整体结果-人数统计
     * EXPORT_SCHOOL_COLLECT_TEACHER_STATISTICS 导出全部
    */
    GET_SCHOOL_ZHENG_LIST: BASE_URL + '/school/schoolCollectDetails/list',
    GET_SCHOOL_ZHENG_LIST: BASE_URL + '/school/schoolCollectForm/getOverallStatistical',
    GET_SCHOOL_ZHENG_XQ: BASE_URL + '/school/schoolCollectForm/info/',
    EXPORT_SCHOOL_COLLECT_TEACHER_STATISTICS: BASE_URL + '/school/schoolCollectTeacherStatistics/export',
    /**
     * 石家庄校外培训跳转 教育局/学校
     * EDUCATION_AUTO_LOGIN 教育局/学校
    */
    EDUCATION_AUTO_LOGIN: BASE_URL + '/auth/sc/educationAutoLogin',
    /**
     * GET_PATROL_RECORD_LIST  巡课记录列表
     * GET_PROGRAMME_LIST   巡课记录-巡课方案列表接口
     * GET_TARGET_BY_PROID 根据方案id查询巡课对象
     * GET_CONTENT_GROUP_LIST 查询所有巡课内容分组信息
     * GET_PATROL_DETAILS_BY_ID 巡课方案详情接口
     * GET_ORGAN_TREE_BY_CLASSIDS  巡课对象班级的下拉列表
     * SAVE_PATROL_RECORD_LIST 保存巡课记录
     * DEL_PATROL_RECORD_LIST 删除巡课记录
     * GET_RECORD_DETAILS_BY_ID  巡课记录详情
     */
    GET_PATROL_RECORD_LIST: BASE_URL + "/school/schoolPatrolRecord/list",
    GET_PROGRAMME_LIST: BASE_URL + "/school/schoolPatrolProgramme/getEffeProList",
    GET_TARGET_BY_PROID: BASE_URL + "/school/schoolPatrolProgramme/getTargetByProId",
    GET_CONTENT_GROUP_LIST: BASE_URL + "/school/schoolPatrolGroup/getAllPatrolGroup",
    GET_PATROL_DETAILS_BY_ID: BASE_URL + "/school/schoolPatrolProgramme/getDetailById",
    GET_ORGAN_TREE_BY_CLASSIDS: BASE_URL + "/school/schoolOrgan/getOrganTreeByClassIds",
    SAVE_PATROL_RECORD_LIST: BASE_URL + "/school/schoolPatrolRecord/save",
    DEL_PATROL_RECORD_LIST: BASE_URL + "/school/schoolPatrolRecord/delete",
    GET_RECORD_DETAILS_BY_ID: BASE_URL + "/school/schoolPatrolRecord/getDetailById",
};
export default {
    API,
};
